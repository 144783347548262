import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 50,
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2079.95 456.05"
    >
      <path d="M379.932 281.258C411.746 281.258 428.298 262.894 435.866 246.454H438.176V278.047H492.058V15.0267H437.533V113.916H435.866C428.553 97.8636 412.773 78.2129 379.803 78.2129C336.57 78.2129 300.008 111.862 300.008 179.672C300.008 245.685 335.03 281.258 379.932 281.258ZM397.252 237.72C370.439 237.72 355.813 213.833 355.813 179.414C355.813 145.253 370.18 121.751 397.252 121.751C423.808 121.751 438.689 144.225 438.689 179.414C438.689 214.603 423.549 237.72 397.252 237.72ZM529.766 278.047H584.418V80.7806H529.766V278.047ZM557.22 55.3538C573.513 55.3538 586.853 42.8948 586.853 27.6123C586.853 12.459 573.513 0 557.22 0C541.056 0 527.712 12.459 527.712 27.6123C527.712 42.8948 541.056 55.3538 557.22 55.3538ZM710.772 281.899C759.522 281.899 792.366 258.141 800.064 221.539L749.515 218.199C744.001 233.226 729.888 241.061 711.67 241.061C684.346 241.061 667.026 222.952 667.026 193.542V193.413H801.217V178.386C801.217 111.348 760.678 78.2129 708.592 78.2129C650.606 78.2129 613.017 119.439 613.017 180.313C613.017 242.859 650.093 281.899 710.772 281.899ZM667.026 159.508C668.182 137.033 685.244 119.054 709.49 119.054C733.225 119.054 749.644 136.005 749.773 159.508H667.026ZM884.308 15.0267H829.656V278.047H884.308V15.0267Z" fill="white" />
      <path d="M912.817 278.978C918.305 278.978 922.75 274.534 922.75 269.047C922.75 263.559 918.305 259.114 912.817 259.114C907.329 259.114 902.885 263.559 902.885 269.047C902.885 274.534 907.329 278.978 912.817 278.978ZM912.817 277.128C908.347 277.128 904.734 273.515 904.734 269.047C904.734 264.577 908.347 260.964 912.817 260.964C917.287 260.964 920.9 264.577 920.9 269.047C920.9 273.515 917.287 277.128 912.817 277.128ZM909.453 273.515H911.055V270.225H913.574L915.288 273.515H917.076L915.127 269.878C916.02 269.406 916.703 268.475 916.703 267.221C916.703 265.359 915.225 264.353 913.848 264.353H909.453V273.515ZM911.055 268.971V265.607H913.363C914.158 265.607 914.965 266.066 914.965 267.221C914.965 268.351 914.17 268.971 913.326 268.971H911.055Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M155.215 15.3763H101.398V101.949L26.9086 58.9426L0 105.55L74.7167 148.687L0.171125 191.726L27.0797 238.333L101.398 195.426V281.899H155.215V195.163L230.815 238.811L257.724 192.204L182.351 148.687L257.895 105.072L230.987 58.4649L155.215 102.212V15.3763Z" fill="url(#paint0_linear_220_26)" />
      <defs>
        <linearGradient id="paint0_linear_220_26" x1="128.948" y1="18.8314" x2="128.948" y2="282.474" gradientUnits="userSpaceOnUse">
          <stop stopColor="#363BC4" />
          <stop offset="1" stopColor="#00FF9B" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoFull;
