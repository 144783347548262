import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 45,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-10 -40 270 400"
    >
      <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M155.215 15.3763H101.398V101.949L26.9086 58.9426L0 105.55L74.7167 148.687L0.171125 191.726L27.0797 238.333L101.398 195.426V281.899H155.215V195.163L230.815 238.811L257.724 192.204L182.351 148.687L257.895 105.072L230.987 58.4649L155.215 102.212V15.3763Z" fill="url(#paint0_linear_220_26)"/>
      <defs>
        <linearGradient id="paint0_linear_220_26" x1="128.948" y1="18.8314" x2="128.948" y2="282.474" gradientUnits="userSpaceOnUse">
          <stop stopColor="#363BC4" />
          <stop offset="1" stopColor="#00FF9B" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoIcon;
